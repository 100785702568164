import algoliasearch from 'algoliasearch/lite'

const searchClient = algoliasearch(
  'GHI7YQ8FQ2',
  'cc990a79c926c87cf5a96b35e7ca0089'
)

const index = searchClient.initIndex('kb')

export default index
